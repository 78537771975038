@import "../node_modules/@introvoke/react/styles/fonts.css";
@import "../node_modules/@introvoke/react/styles/animations.css";

@font-face {
  font-family: "Surt";
  src:
    local("Surt"),
    url("./fonts/Surt/Surt-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Surt";
  src:
    local("Surt"),
    url("./fonts/Surt/Surt-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Surt";
  src:
    local("Surt"),
    url("./fonts/Surt/Surt-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Surt";
  src:
    local("Surt"),
    url("./fonts/Surt/Surt-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Surt";
  src:
    local("Surt"),
    url("./fonts/Surt/Surt-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Surt";
  src:
    local("Surt"),
    url("./fonts/Surt/Surt-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Surt";
  src:
    local("Surt"),
    url("./fonts/Surt/Surt-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Surt";
  src:
    local("Surt"),
    url("./fonts/Surt/Surt-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
#root > div {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* TODO: We need to allow the browser to choose a default, for now set this so we can have something sane */
  font-size: 12px;
  font-family: "Inter", "Arial", sans-serif;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sequel Grotesk", "Arial", "Poppins", "Verdana", sans-serif;
}

body,
#root,
#root > div {
  max-height: 100%;
  overflow: hidden;
}

code {
  font-family: "Poppins", "Verdana", sans-serif;
}

.MuiButtonBase-root:focus {
  outline: none;
}

.video-js {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera to remove the arrows from the input of type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox to remove the arrows from the input of type number */
input[type="number"] {
  -moz-appearance: textfield;
}

.sc-user-input--emoji-icon {
  height: 18px;
  width: 30px;
  cursor: pointer;
  align-self: center;
}

.sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon circle {
  fill: rgba(86, 88, 103, 0.3);
}
.sc-user-input--emoji-icon.active path,
.sc-user-input--emoji-icon.active circle,
.sc-user-input--emoji-icon:hover path,
.sc-user-input--emoji-icon:hover circle {
  fill: rgba(86, 88, 103, 1);
}

.center-horizontally {
  justify-content: center;
}
.center-vertical {
  display: flex !important;
  align-items: center;
}
.account-settings-form .form-group {
  margin: 0px 0px 15px 0px;
  color: black;
}

.account-settings-form .form-group select.custom-select {
  height: 43px;
}

.account-settings-form .registration-genres-step {
  margin-top: 0px;
  margin-bottom: 15px;
}

.sign-up h1 {
  font-size: 18px;
}

.sign-up p {
  font-size: 14px;
}

.sign-up .btn-secondary:disabled,
.btn-primary:disabled {
  color: #000;
  background-color: #808080;
  border-color: #6c757d;
  border: 0.5px solid #6c757d;
}

.sign-up .btn {
  box-shadow: none !important;
  border: 0.5px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sign-up .btn-green-pro {
  padding: 5px 20px;
  border-radius: 3px;
}

.sign-up .box_style_2 {
  border-radius: 3px;
  margin-bottom: 25px;
  border: 0px solid #ddd;
}

.sign-up .btn:hover {
  background: #fb4220;
  color: white !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
}

.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}
.vjs-live-display {
  display: none;
}
.center-block {
  display: block;
  text-align: center;
}
.card-body {
  padding: 30px;
  line-height: 1.6 !important;
  border-top: 0px solid #ddd;
}

.vjs-text-track-display {
  margin-bottom: 48px;
}

@media (max-width: 900px) {
  .vjs-has-started.vjs-live.vjs-user-inactive.vjs-playing .vjs-control-bar {
    display: flex;
    visibility: visible;
    opacity: 1;
  }

  .video-js .vjs-tech {
    position: static;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
  }
  .vjs-text-track-display {
    margin-bottom: 80px;
  }
}
